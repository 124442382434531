import React from 'react';
import Redux, { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store/rootReducer';
import { setErrorState } from '../../../redux/store/Actions/error.actions';
import { WebUtils, ServiceError, Timer } from 'utils';
import {
  CardCodeEnum,
  AwarenessCardOption,
  UpdateUserPrefRequestApp,
  UpdateUserPrefResponseApp,
  AwarenessCard as AwarenessCardModel,
  WebLogLevel,
  ConfigResponseApp,
  ApiRefIdSuffix,
} from 'shared';
import AwarenessCard from '../AwarenessCard';
import { Button } from '@clublabs/ace-component-library';
import { appSources } from 'remotes';
import { ErrorState } from '../../../redux/store/Reducers/error.reducer';
import { updateCardContainerState } from '../../../redux/store/Actions/cardcontainer.actions';
import { CardContainerState } from '../../../redux/store/Reducers/cardcontainer.reducer';
import { tagGetAutoQuoteUrl } from '../../Common/TaggingHelper';

interface RequiredProps {
  cardCode: CardCodeEnum;
  id: string;
  title: any;
  body: any;
  buttonText: string;
  dismissable: boolean;
  ctaURL: string;
  onClose: (card: { code: CardCodeEnum; prodId: string }) => void;
  state: string;
  config?: ConfigResponseApp;
}
interface OptionalProps {
  productId: string;
  options: AwarenessCardOption[];
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    errors: rootState.errorState,
    cardContainerState: rootState.cardContainerState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setErrorState,
      updateCardContainerState,
    },
    dispatch
  );
};

export type AutoQuoteCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;

export interface AutoQuoteCardState {
  isHidden: boolean;
}

export class AutoQuoteCard extends React.Component<AutoQuoteCardProps, AutoQuoteCardState> {
  constructor(props: AutoQuoteCardProps) {
    super(props);
    this.state = {
      isHidden: false,
    };
  }

  public componentDidMount = () => {};

  hide = (value) => {
    this.setState({ isHidden: value });
  };

  handleOptionsClick = (option) => {
    const { sessionId, aceMeta } = WebUtils.getMetadata();
    const request: UpdateUserPrefRequestApp = {
      aceMeta,
      data: {
        productId: this.props.productId,
        prefCode: this.props.cardCode.toString(),
        prefSelection: option.key,
      },
    };
    const updateUserPrefTimer = new Timer('updateUserPrefWeb - autoQuoteCard', sessionId);
    const start = updateUserPrefTimer.startTimer();
    appSources()
      .updateUserPreferece(request)
      .then((response: UpdateUserPrefResponseApp) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb - autoQuoteCard',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'success' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta
        );
        if (response) {
          //remove from allCards redux state
          const allCards =
            this.props.cardContainerState && (this.props.cardContainerState.allCards as AwarenessCardModel[]);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.props.updateCardContainerState &&
            this.props.updateCardContainerState({
              ...this.props.cardContainerState,
              allCards: allCards && allCards.filter((c) => c.code !== this.props.cardCode),
            } as CardContainerState);
          this.hide(true);
          this.props.onClose({
            code: this.props.cardCode,
            prodId: this.props.productId || '',
          });
        }
      })
      .catch((error: ServiceError) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb - autoQuoteCard',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'fail' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta,
          WebLogLevel.error
        );
        if (error && this.props.setErrorState) {
          error.SessionId = `${sessionId}-${ApiRefIdSuffix.AwarenessCards}`;
          this.props.setErrorState({ model: error } as ErrorState);
          WebUtils.logWeb({
            message: 'Failed update user preferences for Auto quote awareness card',
            level: WebLogLevel.error,
            data: WebUtils.constructLogData(
              { error }, 
              this.props?.userState?.customerId, 
              this.props?.userState?.membershipNumber,
            ),
            aceMeta,
          });
        }
      });
  };

  mapHeaderOptions = (options) => {
    return options.map((option) => {
      return { onClick: this.handleOptionsClick, ...option };
    });
  };

  enrollNowClick = () => {
    const { ctaURL, state, config } = this.props;
    let autoQuoteURL = ctaURL;
    if (state === 'MO' && config) {
      autoQuoteURL = config.getAutoQuoteURLMO;
    }

    autoQuoteURL = tagGetAutoQuoteUrl(autoQuoteURL, 'awareness card');
    WebUtils.externalLinkHandler(autoQuoteURL, false, false, this.props?.userState?.customerId, this.props?.userState?.membershipNumber).catch(() => {});
  };

  public render() {
    const { id, body, dismissable, buttonText, cardCode, options, title } = this.props;
    return (
      !this.state.isHidden && (
        <AwarenessCard
          id={id}
          key={`AwarenessCard-${id}`}
          title={title}
          body={body}
          dismissable={dismissable}
          cardHeaderOptions={this.mapHeaderOptions(options)}
          cardActions={[
            <Button
              id="awareness-card1-cta"
              onClick={this.enrollNowClick}
              key={`CardAction-Card-${id}-CardAction-EnrollNow`}
            >
              {buttonText}
            </Button>,
          ]}
          cardCode={cardCode}
        ></AwarenessCard>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoQuoteCard);
