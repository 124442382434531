import {
  AwarenessCardRequestApp,
  AwarenessCardResponseApp,
  AwarenessCardContractApp,
  AutoPayAwarenessCardModelFixture,
  aceMetaFixture,
  AwarenessCard,
  AceMeta,
} from 'shared';
import { RemotePost } from '../fetchers';
import { GetSourceParam, SourceParam } from 'remotes';
import { WebUtils } from 'utils';
import { AwarenessCardsMapper } from 'shared/mappers/customerMapper';

export const AwarenessCardRemoteApp = (endpoint: string): AwarenessCardContractApp => {
  return async (request) => {
    // const apiVersion = GetSourceParam(SourceParam.apiVersion);
    const { aceMeta } = WebUtils.getMetadata();

    // if (apiVersion === 'v3') {
    const resp: AwarenessCardResponseApp = awarenessCardV3(aceMeta);

    // await RemotePost<AwarenessCardRequestApp, AwarenessCardResponseApp>(endpoint)(request);
    return Promise.resolve(resp);
    // }

    // return RemotePost<AwarenessCardRequestApp, AwarenessCardResponseApp>(endpoint)(request);
  };
};

export const AwarenessCardStubApp = (_endpoint: string): AwarenessCardContractApp => {
  return async () => {
    const apiVersion = GetSourceParam(SourceParam.apiVersion);
    if (apiVersion === 'v3') {
      const resp: AwarenessCardResponseApp = awarenessCardV3({
        sessionId: '',
        timestamp: '',
      });
      return resp;
    } else {
      return {
        aceMeta: aceMetaFixture,
        data: {
          awarenessCards: [AutoPayAwarenessCardModelFixture],
        },
      };
    }
  };
};

function awarenessCardV3(aceMeta: AceMeta) {
  const awarenessCards: AwarenessCard[] = AwarenessCardsMapper();
  const resp: AwarenessCardResponseApp = {
    aceMeta: {
      sessionId: aceMeta.sessionId,
      timestamp: aceMeta.timestamp,
    },
    data: {
      awarenessCards,
    },
  };
  return resp;
}
