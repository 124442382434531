/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetSourceParam, RemotePost, SetSourceParam, SourceParam, configService, fetchers, httpInstance } from 'remotes';
import {
  ACEPrimaryTheme,
  TabGroup,
  LayoutContainer,
  Spinner,
  ButtonGroup,
  Button,
} from '@clublabs/ace-component-library';
import HomeTabContent from '../Home/HomeTabContent';
import { AceHeader, AceFooter } from '@clublabs/shared-nav-webcomponents-react';
import {
  tagApplicationPageLoad,
  tagMyAccountView,
  tagPayBillView,
  tagProfileView,
  tagProfilePageLoad,
  loadUtagJs,
  tagCancelScheduledPaymentFail,
  tagCancelScheduledPaymentSuccess,
} from '../Common/TaggingHelper';
import Welcome from '../Welcome/Welcome';
import {
  CustomerDetailsResponseApp,
  taggingMapper,
  createAnalytics,
  ConfigResponseApp,
  ClubCodes,
  CustomerDetails,
  TaggingSource,
  FormPostKeyValue,
  WebLogLevel,
  ApiStatus,
  authenticateFixture,
  CustomerDetailsProduct,
  ProductType,
  networkBrowserErrors,
  TaggingModel,
} from 'shared';
import { setUserState } from '../../redux/store/Actions/user.actions';
import { UserState } from '../../redux/store/Reducers/user.reducer';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { ErrorState } from '../../redux/store/Reducers/error.reducer';
import { setAnalyticsState } from '../../redux/store/Actions/analytics.actions';
import { AnalyticsState } from 'redux/store/Reducers/analytics.reducer';
import { setActiveTab } from '../../redux/store/Actions/tabcontainer.actions';
import { RootState } from '../../redux/store/rootReducer';
import { ServiceError, Timer, utility, WebUtils } from 'utils';
import ProfileTabContent, { ProfileTabProps } from '../Profile/ProfileTabContent';
import ErrorComp from '../Error/ErrorComp';
import SecurityModal from '../Common/SecurityModal';
import CancelledPolicyModal from '../Common/CancelledPolicyModal';
import { setModalState } from '../../redux/store/Actions/modal.actions';
import { setCancelPaymentState } from 'redux/store/Actions/cardcontainer.actions';
import { ModalState } from '../../redux/store/Reducers/modal.reducer';
import jwtDecode from 'jwt-decode';
import { useEffect, useState, useRef } from 'react';
import { useGlobalState } from 'shared/state';
import { appSources } from 'remotes';
import { ProfileTabEnum } from 'shared/common/profileTab';
import Cookies from 'universal-cookie';
import AceIdentity from '@clublabs/ace-identity';
import { ErrorTypes, MembershipStatus } from '../../shared';
import Error from '../Error/Error';
import LogCannon from '@clublabs/log-cannon';
import { TeamValue, WorkloadValue } from '@clublabs/aws-tags';
import { v4 as uuid } from 'uuid';
import { Customer } from 'shared/mappers/customerMapper';
import CancelPaymentModal, { CancelPaymentModalProps } from 'Components/Common/CancelPaymentModal';
import { AxiosRequestConfig } from 'axios';
import { CardContainerState } from 'redux/store/Reducers/cardcontainer.reducer';

const initialCustomerDetailData = {
  data: {
    welcomeMessage: '',
    customerId: '',
    preferredEmailAddress: {
      emailAddress: '',
      lastUpdateDate: '',
    },
    numberOfProducts: 0,
    products: [] as CustomerDetailsProduct[],
    emptyAuthorizations: false,
  },
  status: ApiStatus.UNINITIATED,
};

const App: React.FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const [aceMeta, setAceMeta] = useGlobalState('aceMeta');
  const [clubCode, setClubCode] = useState(WebUtils.getClubCode());
  const [authToken, setAuthToken] = useState('');
  const [notificationError, setNotificationError] = useState(false);
  const [config, setConfig] = useGlobalState('config');
  const [source, setSource] = useGlobalState('appSources');
  const [membershipStatus, setMembershipStatus] = useState<MembershipStatus>(MembershipStatus.Valid);
  const [activeTabs, setActiveTabs] = useState([
    {
      id: '1',
      value: ProfileTabEnum.MY_ACCOUNT,
      content: <React.Fragment />,
    },
    // {
    //   id: '2',
    //   value: ProfileTabEnum.PAY_BILLS,
    //   content: <React.Fragment />,
    // },
    {
      id: '3',
      value: ProfileTabEnum.PROFILE,
      content: <React.Fragment />,
    },
  ]);

  const [customerDetails, setCustomerDetails] = useState<{ data: CustomerDetails; status: ApiStatus }>(
    initialCustomerDetailData
  );

  const [loggedIn, setLoggedIn] = useState(false);

  const userState = useSelector<RootState, UserState>((state) => state.userState);
  const modalState = useSelector<RootState, ModalState>((state) => state.modalState as ModalState);
  const analyticsState = useSelector<RootState, AnalyticsState>((state) => state.analyticsState as AnalyticsState);
  const cardContainerState = useSelector<RootState, CardContainerState>((state) => state.cardContainerState as CardContainerState);
  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  const initialTab = useRef(activeTabs[0].id);

  interface CancelScheduledPaymentPayload {
    RequestSource: string,
    InsRequestFunction: string,
    InsConfirmationNbr: string,
    InsMemberName: string,
    InsChangeReqSource: string,
    InsPayAmount: string,
    InsPayScheduleDate?: string,
    InsPolicyPrefix: string,
    InsPolicyNumber: string,
    InsPaySubmitDate: string,
    MbrClubCode: string,
    MbrChangeReqSource: string,
    MbrEmpNumber: string,
    MbrEmpDistOffNumber: string,
    MbrEmpSection: string
  };

  const onSelectTab = (selectedTab) => {
    const tabData = activeTabs;
    const tabId: number = parseInt(selectedTab.id);
    switch (tabId) {
      case 1:
        tagMyAccountView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 2:
        tagPayBillView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 3:
        tagProfileView(analyticsState.model);
        dispatch(setActiveTab(tabData[tabId - 1]));
        break;
      case 0:
      default:
        tagApplicationPageLoad(analyticsState.model);
    }
  };

  useEffect(() => {
    if (config) {
      const tempTabs = [...activeTabs];
      tempTabs[0].value = config.Tab1 || ProfileTabEnum.MY_ACCOUNT;
      // const tab2Label = config.Tab2 || ProfileTabEnum.PAY_BILLS;
      tempTabs[1].value = config.Tab3 || ProfileTabEnum.PROFILE;
      tempTabs[0].content = (
        <HomeTabContent
          customerDetails={customerDetails.data}
          config={config}
          customerDetailsStatus={customerDetails.status}
          showNotificationError={notificationError}
        />
      );
      const profileTabProps = {
        manageAutoPayURL: config.manageAutoPayURL,
        manageContactInfoURL: config.MembershipURLs?.membershipChangeContactInfoURL,
        managePasswordURL: config.managePasswordURL,
        managePaymentAccountsURL: config.managePaymentAccountsURL,
        manageCommPrefsURL: config.manageCommPrefsURL,
        logoutURL: config.logoutURL,
        homeURL: config.HomeURL,
        manageUsernameConfig: config.ManageUsernameEnabled,
        clubCode,
        ManageAddressChange: config.ManageAddressChangeEnabled,
        manageAddressURL: config.MembershipURLs?.ManageAddressChangeURL,
        config,
      } as ProfileTabProps;
      // tempTabs[1].content = (
      //   <PayBillsTabContent
      //     config={config}
      //     customerDetailsStatus={customerDetails.status}
      //     analyticsStatus={analyticsStatus}
      //   />
      // );
      tempTabs[1].content = <ProfileTabContent {...profileTabProps} />;
      setActiveTabs(tempTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsState, config, clubCode, customerDetails]);

  const setTaggging = (analyticsResp: TaggingModel) => {
    const customerData: Customer = GetSourceParam(SourceParam.customerData);
    const customerApiError = customerData.apiStatus?.customer === 'rejected';

    const productsData: Customer = GetSourceParam(SourceParam.productsData);
    const billingApiError = productsData.apiStatus?.billing === 'rejected';
    const policyApiError = productsData.apiStatus?.policy === 'rejected';

    let error = '';
    const message = 'my account:manage my account-unable to retrieve';

    if (Object.keys(productsData).length === 0 || (billingApiError && policyApiError)) {
      error = `${message} billing/product information`;
    } else if (billingApiError) {
      error = `${message} billing information`;
    } else if (policyApiError) {
      error = `${message} product information`;
    } else if (Object.keys(customerData).length === 0 || customerApiError) {
      error = `${message} customer information`;
    }

    analyticsResp.error = error;
    tagApplicationPageLoad(analyticsResp);
  };

  useEffect(() => {
    const loadCustomer = async () => {
      if (config) {
        let token;
        if (config.identityUsePkce) {
          const areaQueryParam = 'area';
          const areaStorageValue = sessionStorage.getItem(areaQueryParam);
          const searchParams: { query: string; value: string }[] = [];
          if (areaStorageValue) {
            searchParams.push({ query: areaQueryParam, value: areaStorageValue });
          }

          const aceIdentity = new AceIdentity({
            env: config.identityEnv,
            redirectUri: config.identityRedirectUri,
            loginUrl: config.identityLoginUrl,
            searchParams,
            bypassAuth2: true,
          });
          await aceIdentity.ready();
          const tokens = await aceIdentity.tokens();
          token = tokens?.accessToken;
        }

        const loginUrl = WebUtils.getLoginUrl(config);
        // for our clubs login url is always exists, otherwis - bailout
        if (!loginUrl) return;

        sessionStorage.setItem('loginUrl', loginUrl);

        const responseFromAuthenticationLogin = new URLSearchParams(
          process.env.REACT_APP_SOURCES === 'stubs' ? authenticateFixture.locationSearch : window.location.search
        );

        const tokenFromQueryParam = responseFromAuthenticationLogin.get('token');
        const tabOption = responseFromAuthenticationLogin.get('tabOption');

        const cookies = new Cookies();

        if (tokenFromQueryParam) {
          token = tokenFromQueryParam;
        }

        if (tabOption) {
          const initialActiveTab = activeTabs.find((tab) => tab.value.toUpperCase() === tabOption.toUpperCase());
          if (initialActiveTab) {
            initialTab.current = initialActiveTab.id;
          }
        }

        if (tokenFromQueryParam) {
          responseFromAuthenticationLogin.delete('token');
          responseFromAuthenticationLogin.delete('cid');
          responseFromAuthenticationLogin.delete('ts');
          const domain = new URL(window.location.href);

          const port = domain.port ? `:${domain.port}` : '';
          const queryParams = responseFromAuthenticationLogin.toString()
            ? `?${responseFromAuthenticationLogin.toString()}`
            : '';
          const newState = {
            title: document.title,
            url: `${domain.protocol}//${domain.hostname}${port}${queryParams}`,
          };

          window.history.replaceState(newState, newState.title, newState.url);
        }

        if (token) {
          //set version
          const version = process.env.REACT_APP_VERSION ?? '';
          localStorage.setItem('aceVersion', version);

          //TODO: check for existing sessionId

          const sessionId = WebUtils.getSessionId();
          httpInstance.defaults.headers['x-ace-session-id'] = sessionId;

          // const apiKey = 'NtVBQzVdIRaa4QCQ5EdCc7jB5kOzoO';
          // LogCannon.init(apiKey, {
          //   team: TeamValue.Enterprise as any,
          //   appUrl: window.location.toString().split('?')[0],
          //   workload: WorkloadValue.Enterprise as any,
          //   component: 'myaccount',
          //   runtime: 'nodejs',
          //   runtimeVersion: process.version,
          // });

          httpInstance.defaults.headers.authorization = `Bearer ${token}`;
          setAuthToken(token);
          const decodedPayload = Object.assign(
            { clubCode: '', customerId: '', jti: '', membershipStatus: '', memberNumber: '' },
            jwtDecode(token)
          );
          cookies.set('aceu', decodedPayload.customerId);
          sessionStorage.setItem('clubCode', decodedPayload.clubCode);
          setClubCode(decodedPayload.clubCode as ClubCodes);
          dispatch(
            setUserState({
              sessionId,
              customerId: decodedPayload.customerId,
              clubCode: decodedPayload.clubCode,
            } as UserState)
          );
          const { aceMeta } = WebUtils.getMetadata({
            //TODO: refactor
            customerId: decodedPayload.customerId,
            membershipNumber: decodedPayload.memberNumber,
            clubCode: decodedPayload.clubCode,
          });
          setAceMeta(aceMeta);
          const correlationIdConfig = uuid();
          const configRequest = { aceMeta: { ...aceMeta, correlationId: correlationIdConfig } };
          const configTimer = new Timer('configTimer', sessionId as any);
          const configStart = configTimer.startTimer().start;

          let currentSources = source;

          try {
            //TODO: make aceMeta a global variable
            LogCannon.blast('config - start', {
              aceMeta: { ...aceMeta, correlationId: correlationIdConfig },
              start: configStart,
            });

            //TODO: add cache to this call (perhaps using react-query) to avoid duplicates call between app.tsx and appContainer.tsx
            const configResponse = await configService(configRequest);
            const { end, responseTime } = configTimer.endTimer();
            
            const newAceMeta = { ...aceMeta, ...configResponse?.aceMeta, correlationId: correlationIdConfig };
            setAceMeta(newAceMeta);
            SetSourceParam(SourceParam.aceMeta, newAceMeta);

            LogCannon.blast('config - end', {
              aceMeta: newAceMeta,
              metrics: { start: configStart, end, responseTime },
              status: 'success',
              rate: 100,
              api: 'config'
            });
            setConfig(configResponse.data);
            SetSourceParam(SourceParam.config, configResponse.data);
            const endPoints = configResponse.data?.ApiEndpoints;
            if (endPoints) {
              currentSources = appSources(endPoints);
              setSource(currentSources);
            }
            sessionStorage.setItem('loginUrl', WebUtils.getLoginUrl(config));

            // V3 considerations from the config and modify session storage
            // const apiVersionV3 = configResponse.data?.ApiVersionV3 ?? [];
            // const hasClubCode: boolean = apiVersionV3.includes(decodedPayload.clubCode) || apiVersionV3.includes('ALL');
            // const apiVersionNumberV3 = hasClubCode ? 'v3' : 'v1';

            //sessionStorage.setItem('apiVersion', apiVersionNumberV3);

            // Deprecated code
            // let storedVersion = sessionStorage.getItem('apiVersion');
            // if (!storedVersion) {
            //   sessionStorage.setItem('apiVersion', apiVersionNumberV3);
            // }
          } catch (error: any) {
            const { end, responseTime } = configTimer.endTimer();
            LogCannon.error('config - end', {
              aceMeta,
              metrics: { start: configStart, end, responseTime },
              status: 'fail',
              error,
              rate: 0,
              api: 'config'
            });
            if (!networkBrowserErrors.includes(error.ComponentErrorCode)) {
              dispatch(setErrorState({ model: error as ServiceError }));
            }
          }

          const apiVersionFromSource = 'v3';
          SetSourceParam(SourceParam.apiVersion, apiVersionFromSource);

          // deprecated code
          // const v3 = sessionStorage.getItem('apiVersion');
          // if (v3) {
          //   SetSourceParam(SourceParam.apiVersion, v3);
          // } else {
          //   SetSourceParam(SourceParam.apiVersion, 'v1');
          // }
          // const apiVersionFromSource = GetSourceParam(SourceParam.apiVersion);

          const correlationIdCustomer = uuid();
          const correlationIdProducts = uuid();
          const requestCustomer = {
            aceMeta: { ...aceMeta, correlationId: correlationIdCustomer, apiVersion: apiVersionFromSource },
          };


          // try {
          // const cciCalls = [source.customer(requestCustomer)];
          // if (apiVersionFromSource === 'v3') {
          //   LogCannon.blast('products - start', {
          //     aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
          //     start: startCCI,
          //   });
          //   cciCalls.push(source.products(requestProducts));
          // }
          // const [customerData, productsData] = await Promise.allSettled(cciCalls);

          const processError = (
            data: PromiseSettledResult<any>,
            apiName: string,
            key: SourceParam,
            correlationId: string,
            metrics: any
          ) => {
            if (data.status === 'fulfilled') {
              const dv: any = data.value;
              let cnt = 0,
                rate = 0;
              if (dv?.apiStatus)
                Object.keys(dv?.apiStatus).forEach((k) => {
                  cnt++;
                  rate += dv.apiStatus[k] === 'fulfilled' ? 1 : 0;
                });

              LogCannon.blast(`${apiName} - end`, {
                aceMeta: { ...aceMeta, ...data?.value?.aceMeta },
                metrics,
                status: 'success',
                rate: cnt ? ~~((100 * rate) / cnt) : 0,
                api: apiName,
              });
              SetSourceParam(key, data.value);
            } else {
              LogCannon.error(`${apiName} - end`, {
                aceMeta: { ...aceMeta, correlationId },
                status: 'fail',
                error: data.reason,
                metrics,
                rate: 0,
                api: apiName,
              });
              setNotificationError(true);
            }
          };

          const customerTimer = new Timer('customerTimer', sessionId);
          const { start: startCCI } = customerTimer.startTimer();

          LogCannon.blast('customer - start', {
            aceMeta: { ...aceMeta, correlationId: correlationIdCustomer },
            start: startCCI,
          });
          const [customerData] = await Promise.allSettled([currentSources.customer(requestCustomer)]);

          {
            const { end, responseTime } = customerTimer.endTimer();
            const metrics = {
              start: startCCI,
              end,
              responseTime,
            };
            processError(customerData, 'customer', SourceParam.customerData, correlationIdCustomer, metrics);
          }

          const productsTimer = new Timer('productsTimer', sessionId);
          const { start: startCCIP } = productsTimer.startTimer();

          LogCannon.blast('products - start', {
            aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
            start: startCCIP,
          });
          const requestProducts = {
            aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
            requestPolicies: (customerData as any)?.value?.customerDetails?.numberOfProducts > 0 ? 'true' : 'false',
          };
          const [productsData] = await Promise.allSettled([currentSources.products(requestProducts)]);


          //TODO: send aws and backend regions in api response so they can be captured in logs

          // if (customerData.status === 'fulfilled') {
          //   LogCannon.blast('customer - end', {
          //     aceMeta: { ...aceMeta, ...customerData?.value?.aceMeta },
          //     metrics,
          //     status: 'success',
          //   });
          //   SetSourceParam(SourceParam.customerData, customerData.value);
          // } else {
          //   LogCannon.error('customer - end', {
          //     aceMeta: { ...aceMeta, correlationId: correlationIdCustomer },
          //     status: 'fail',
          //     error: customerData.reason,
          //     metrics,
          //   });
          //   setNotificationError(true);
          // }

          if (productsData) {
            const { end, responseTime } = productsTimer.endTimer();
            const metrics = {
              start: startCCI,
              end,
              responseTime,
            };

            processError(productsData, 'products', SourceParam.productsData, correlationIdProducts, metrics);

            // if (productsData.status === 'fulfilled') {
            //   LogCannon.blast('products - end', {
            //     aceMeta: { ...aceMeta, ...productsData?.value?.aceMeta },
            //     metrics,
            //     status: 'success',
            //   });
            //   SetSourceParam(SourceParam.productsData, productsData.value);
            // } else {
            //   LogCannon.error('products - end', {
            //     aceMeta: { ...aceMeta, correlationId: correlationIdProducts },
            //     metrics,
            //     status: 'fail',
            //     error: productsData.reason,
            //   });
            //   setNotificationError(true);
            // }
          }
          // } catch (error: any) {
          //   const { end, responseTime } = cciTimer.endTimer();
          //   LogCannon.error('error calling cci', {
          //     aceMeta,
          //     metrics: { start: startCCI, end: end, responseTime },
          //     status: 'fail',
          //     error,
          //   });
          // }

          const customerDetailsTimer = new Timer('customerDetailsWeb', sessionId);
          const start = customerDetailsTimer.startTimer();

          currentSources
            .customerDetails(requestCustomer)
            .then((customerDetailResp: CustomerDetailsResponseApp) => {
              const { customerDetails } = customerDetailResp?.data;
              const membershipNumber: string =
                customerDetails?.products?.find((p) => p.type === ProductType.MEMBERSHIP)?.productId || '';

              // WebUtils.logMetrics(
              //   'customerDetailsWeb',
              //   WebUtils.constructLogData(
              //     { str: start, end: customerDetailsTimer.endTimer(), status: 'success' },
              //     decodedPayload?.customerId,
              //     membershipNumber
              //   ),
              //   aceMeta
              // );

              setCustomerDetails({
                data: customerDetails,
                status: ApiStatus.SUCCESS,
              });

              // queue macrotask to hide spinner
              setTimeout(() => setLoggedIn(true), 100);
              dispatch(
                setUserState({
                  ...customerDetails.user,
                  membershipNumber,
                  sessionId,
                  pageLoadSuccessful: true,
                  products: customerDetails.products,
                  state:
                    (customerDetails.person &&
                      customerDetails.person.residenceAddress &&
                      customerDetails.person.residenceAddress.address &&
                      customerDetails.person.residenceAddress.address.state) ||
                    '',
                } as UserState)
              );
              const mda = createAnalytics({ customerDetails });
              cookies.set('MDA', mda);

              // load utag libraries after setting MDA cookie
              utility.loadExternalJavascriptLibraries('//tms.ace.aaa.com/ace/prod/utag.sync.js');
              loadUtagJs();

              const analyticsResp: TaggingModel = taggingMapper({ customerDetails });
              analyticsResp.clubcode = clubCode;
              analyticsResp.sessionId = sessionId;
              dispatch(setAnalyticsState({ model: analyticsResp }));

              const membershipStatus = decodedPayload?.membershipStatus?.trim()?.toLowerCase();
              if (membershipStatus === MembershipStatus.Cancelled) {
                const error: any = { message: 'Membership cancelled' };
                WebUtils.logWeb({
                  message: error.message,
                  level: WebLogLevel.error,
                  data: WebUtils.constructLogData({ error }, decodedPayload?.customerId, userState?.membershipNumber),
                  aceMeta,
                });
                setMembershipStatus(MembershipStatus.Cancelled);
              } else {
                // at this point setAnalyticsState is not finished until next reload so it need to use raw value from analyticsResp
                tabOption?.toLowerCase() === 'profile' ? tagProfilePageLoad(analyticsResp) : setTaggging(analyticsResp);
              }
            })

            .catch((error: ServiceError) => {
              WebUtils.logMetrics(
                'customerDetailsWeb',
                WebUtils.constructLogData(
                  { str: start, end: customerDetailsTimer.endTimer(), status: 'fail' },
                  decodedPayload?.customerId,
                  userState?.membershipNumber
                ),
                aceMeta,
                WebLogLevel.error
              );
              if (!networkBrowserErrors.includes(error.ComponentErrorCode)) {
                dispatch(setErrorState({ model: error } as ErrorState));
              }
              WebUtils.logWeb({
                message: 'Failed to load customerDetails',
                level: WebLogLevel.error,
                data: WebUtils.constructLogData({ error }, decodedPayload?.customerId, userState?.membershipNumber),
                aceMeta: requestCustomer.aceMeta,
              });
            });
        } else {
          // only redirect for non-pkce flow
          // as for pkce, the redirect is automatically handled by ace-identity lib
          if (!config.identityUsePkce) {
            WebUtils.externalLinkHandler(loginUrl, false, true).catch(() => { });
          }
        }
      }
    };

    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeCancelPaymentModal = (key: string, updateCancelBtn: boolean) => {

    dispatch(
      setModalState({
        ...modalState,
        [key]: false,
      } as ModalState)
    );
    if (updateCancelBtn)
      dispatch(setCancelPaymentState(modalState.cardData?.productId || ''));
  };

  const handleYesOptionForCancelPaymentModal = async () => {
    const sessionId = WebUtils.getSessionId();
    const cciTimer = new Timer('cciTimer', sessionId);
    const { start: startCCI } = cciTimer.startTimer();

    if (modalState.cardData && modalState.cardData.amount) {

      try {
        const payload: CancelScheduledPaymentPayload = {
          'RequestSource': "WEB",
          'InsRequestFunction': "C",
          'InsConfirmationNbr': "",
          'InsMemberName': userState.customerName,
          'InsChangeReqSource': "WEB",
          'InsPayAmount': modalState.cardData.amount,
          'InsPayScheduleDate': modalState.cardData.dateInternal,
          'InsPolicyPrefix': modalState.cardData.productId.substring(0, 3),
          'InsPolicyNumber': modalState.cardData.productId.substring(3),
          'InsPaySubmitDate': "",
          'MbrClubCode': clubCode,
          'MbrChangeReqSource': "WEB",
          'MbrEmpNumber': "999999",
          'MbrEmpDistOffNumber': "99",
          'MbrEmpSection': "1"
        };

        const requestConfig: AxiosRequestConfig = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        };

        setIsCanceling(true);
        const response = await fetchers.post<Response>(config?.cancelScheduledPaymentURL, payload, requestConfig);
        if (response.status > 399) {
          throw (`cancelScheduled payment failed with status ${response.status}`);
        }

        dispatch(
          setModalState({
            ...modalState,
            cancelPaymentModal: false,
            cancelPaymentConfirmationModal: true,
            cancelPaymentSuccess: true,
          } as ModalState)
        );
        tagCancelScheduledPaymentSuccess(analyticsState.model);
        setIsCanceling(false);
      }
      catch (error: any) {
        const { end, responseTime } = cciTimer.endTimer();
        LogCannon.error('error calling cancelScheduledPayment ', {
          aceMeta,
          metrics: { start: startCCI, end: end, responseTime },
          status: 'fail',
          error,
        });

        dispatch(
          setModalState({
            ...modalState,
            cancelPaymentModal: false,
            cancelPaymentConfirmationModal: true,
            cancelPaymentSuccess: false,
          } as ModalState)
        );
        tagCancelScheduledPaymentFail(analyticsState.model);
        setIsCanceling(false);
      }
    }
  };

  if (!loggedIn || !config) {
    return (
      <ACEPrimaryTheme>
        <LayoutContainer>
          <div className="spinnerContainer">
            <Spinner id="appLoginSpinner" variant="indeterminate" />
          </div>
          <ErrorComp appConfig={config as ConfigResponseApp} />
        </LayoutContainer>
      </ACEPrimaryTheme>
    );
  }

  const handleCancelledPolicyModalClick = (hasLapseAccidents: boolean) => {
    const extraFields: FormPostKeyValue[] = modalState && modalState.extraFields ? modalState.extraFields : [];
    const payBillUrl = modalState && modalState.payBillUrl ? modalState.payBillUrl : '';
    let formData;
    if (Array.isArray(extraFields)) {
      const find = extraFields.find((k) => k.key === 'hasLapseAccidents');

      if (find) {
        // old billing payload
        find.value = hasLapseAccidents;
      } else {
        // handle billing v2 payload
        extraFields.forEach((k) => {
          if (k.key === 'policies') {
            const strPol = k.value;
            const pol = JSON.parse(strPol);

            pol?.forEach((p) => {
              p.hasLapseAccidents = hasLapseAccidents;
            });
            k.value = JSON.stringify(pol);
          }
        });
      }
      formData = extraFields;
    }
    WebUtils.FormPost(formData, payBillUrl, userState?.customerId, userState?.membershipNumber);
    dispatch(setModalState({ cancelledPolicyModal: false } as ModalState));
  };

  const handleHeaderLinkClick = (ev) => {
    ev.preventDefault();
    const newPage = ev.detail.target === '_blank';
    if (ev.detail.title === 'log out') {
      WebUtils.handleLogout(config.logoutURL, config.HomeURL, userState.customerId, userState.membershipNumber);
      return;
    }
    WebUtils.externalLinkHandler(ev.detail.url, newPage).catch(console.error);
  };

//        disableAEMLinks={true}

  return (
    <ACEPrimaryTheme>
      <AceHeader
        disableMyAccountLink={true}
        onLinkClicked={handleHeaderLinkClick}
        firstName={userState?.customerName || ''}
        isLoggedIn={true}
        showMobileMenu={config.showMobileMenu}
      ></AceHeader>
      <main style={{ flex: '1 0 auto' }}>
        <LayoutContainer>
          {membershipStatus === MembershipStatus.Valid ? (
            <>
              <Welcome message={customerDetails.data.welcomeMessage} />
              <TabGroup id="nav-tabs" tabs={activeTabs} onSelect={onSelectTab} value={initialTab.current} />
              <ErrorComp appConfig={config} />
            </>
          ) : (
            <Error error={{} as any} type={ErrorTypes.MembershipCancelled} clubCode={clubCode} />
          )}
        </LayoutContainer>
      </main>
      <AceFooter club={clubCode}></AceFooter>
      <SecurityModal
        onClick={() =>
          dispatch(
            setModalState({
              ...modalState,
              securityModal: false,
            } as ModalState)
          )
        }
        openModal={modalState.securityModal || false}
        description={modalState.securityModalDescription}
        actionMessage={modalState.securityModalMessage}
      />
      <CancelPaymentModal
        disableCloseIcon={isCanceling}
        onClick={() => closeCancelPaymentModal('cancelPaymentModal', false)}
        id="cancelPayModal"
        title={'Are you sure you want to cancel payment?'}
        actionButtons={
          <>
            {isCanceling ? (<div className="spinnerCancelContainer">
              <Spinner id="spinner" variant="indeterminate" message="" />
            </div>) : <></>
            }
            <ButtonGroup>
              <Button
                id="cancelModalNo"
                color="secondary"
                onClick={() => closeCancelPaymentModal('cancelPaymentModal', false)}
                disabled={isCanceling}
              >
                No
              </Button>
              <Button id="cancelModalyes" color="primary" onClick={handleYesOptionForCancelPaymentModal} disabled={isCanceling}>
                Yes
              </Button>
            </ButtonGroup>
          </>
        }
        openModal={modalState.cancelPaymentModal || false}
      />
      <CancelPaymentModal
        onClick={() => closeCancelPaymentModal('cancelPaymentConfirmationModal', modalState.cancelPaymentSuccess)}
        id="cancelDismissModal"
        title={modalState.cancelPaymentSuccess ? 'Scheduled payment canceled' : 'We were unable to cancel your payment'}
        subTitle={modalState.cancelPaymentSuccess ? 'To ensure uninterrupted coverage, please make a payment before the due date.' : "We encountered an issue and couldn't cancel your scheduled payment. Please try again later."}
        actionButtons={
          <ButtonGroup>
            <Button
              id="cancelModaldismiss"
              color="primary"
              onClick={() => closeCancelPaymentModal('cancelPaymentConfirmationModal', modalState.cancelPaymentSuccess)}
            >
              Dismiss
            </Button>
          </ButtonGroup>
        }
        openModal={modalState.cancelPaymentConfirmationModal || false}
      />
      <CancelledPolicyModal
        openModal={Boolean(modalState.cancelledPolicyModal)}
        actionMessage={modalState.cancelledPolicyMessage}
        description={modalState.cancelledPolicyDescription}
        taggingSource={modalState.taggingSource as TaggingSource}
        onClick={(yesNo) => handleCancelledPolicyModalClick(yesNo)}
      />
    </ACEPrimaryTheme>
  );
};

export default App;
