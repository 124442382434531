import React from 'react';
import Redux from 'redux';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, CardHeader, Grid, withStyles } from '@material-ui/core';
import cx from 'clsx';
import { Body, Button } from '@clublabs/ace-component-library';
import { PITNotificationModel, NotificationType, ConfigResponseApp, ProductType } from 'shared';
import notificationCardStyles, { StyleProps } from './NotificationCardStyles';
import { NotificationHandler, HandleViewClickArguments } from '../Common/NotificationHandlers';
import { RootState } from '../../redux/store/rootReducer';
import { utility } from 'utils';
import { AutoPolicyPrefixes, ProductStatus } from '../../shared/common/enums';

export interface RequiredProps {
  cardData: PITNotificationModel;
  config: ConfigResponseApp;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    rootState: rootState,
    userState: rootState.userState,
    analytics: rootState.analyticsState,
    errors: rootState.errorState,
    tabState: rootState.tabContainerState,
    modalState: rootState.modalState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return {
    urlClick: (args) => NotificationHandler.handleUrlClick(dispatch, args),
    cancelClick: (args) => NotificationHandler.handleCancelClick(dispatch, args),
    payClick: (args) => NotificationHandler.handlePayClick(dispatch, args),
    viewBillClick: (args, config) => NotificationHandler.handleViewClick(dispatch, args, config),
  };
};

export type PaymentInTransitCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  StyleProps;

export const PaymentInTransitCard: React.FunctionComponent<PaymentInTransitCardProps> = (props) => {
  const { paymentsInTransit, currentBalance } = props.cardData;

  let totalAmount = (paymentsInTransit && paymentsInTransit.pitTotalPaymentAmount) || '0';
      totalAmount = (+totalAmount).toFixed(2); // Format with 2 decimals
  let balance = currentBalance || "0.00";
  const remainingBal = parseFloat(balance); 
  const showRemainingBal = remainingBal > 0 && props.cardData.productStatus !== 'Cancelled';
  const productId =
    props.cardData.productType === ProductType.MEMBERSHIP
      ? utility.formatMembership(props.cardData.productId)
      : props.cardData.productId;

  const renderPaymentRows = () => {
    if (paymentsInTransit && paymentsInTransit.pitPayments && paymentsInTransit.pitPayments.length) {
      return paymentsInTransit.pitPayments.map((pp, i) => (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          key={i}
          data-quid="payment-in-transit-row"
        >
          <Grid item xs={6}>
            <Body id="payment-in-transit-type">{pp.pitPaymentType}</Body>
          </Grid>
          <Grid item xs={6} className={props.classes.rightColumn} data-quid="payment-in-transit-amount">
            $ {pp.pitPaymentAmount}
          </Grid>
        </Grid>
      ));
    }

    return null;
  };
  const taggingModel = props.analytics && props.analytics.model;
  const viewBillClickProps: HandleViewClickArguments = {
    notification: props.cardData,
    taggingModel: taggingModel!,
    userState: props.userState!,
    tabState: props.tabState!,
  };
  return (
    <Card data-quid="notifications" className={props.classes.notificationCard} key={props.cardData.productId}>
      <CardHeader
        className={props.classes.cardHeader}
        title={props.cardData.title}
        subheader={<span data-di-mask>{`${props.cardData.productIdLabel} #: ${productId}`}</span>}
      ></CardHeader>
      <CardContent className={props.classes.cardContent}>
        {props.cardData.type !== NotificationType.BILLING_REINSTATE && (
          <Body id="pay-plan">
            Pay plan: {props.cardData.payPlan} | Auto Pay: {props.cardData.isAutoPay ? 'Yes' : 'No'}
          </Body>
        )}

        <div id="pay-status" className={props.classes.payStatus}>
          <Body id="notification-status" className={props.classes.bold}>
            {props.config && props.config.Messages.NotificationMessages.PaymentInTransit}
          </Body>
        </div>
        <Grid container className={props.classes.pitGrid}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <Body id="pit-card-body-payment-method" className={props.classes.bold}>
                Payment Method
              </Body>
            </Grid>
            <Grid item xs={6} className={cx(props.classes.rightColumn, props.classes.bold)}>
              Amount
            </Grid>
          </Grid>
          {renderPaymentRows()}
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <Body id="pit-card-body-total-amount">Total amount:</Body>
            </Grid>
            <Grid
              item
              xs={6}
              className={cx(props.classes.rightColumn, props.classes.bold)}
              data-quid="payment-in-transit-total-amount"
            >
              $ {totalAmount}
            </Grid>
          </Grid>
          {showRemainingBal && (
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <Body id="pit-card-body-total-amount">Remaining balance:</Body>
              </Grid>
              <Grid
                item
                xs={6}
                className={cx(props.classes.rightColumn, props.classes.bold)}
                data-quid="payment-in-transit-total-amount"
              >
                $ {remainingBal.toFixed(2)}
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={props.classes.customFlex}>
          <Body id="pay-wait-notification" className={props.classes.amountText}>
            <span className={props.classes.payWaitNotification}>
              Please allow up to 3 business days for your payment to be reflected in your account.
            </span>
          </Body>
        </div>
      </CardContent>
      <CardActions className={props.classes.cardActions}>
        {props.cardData.paymentEnabled && (
          <Button
            id="pay-bill"
            onClick={() =>
              props.payClick &&
              props.payClick({
                notification: props.cardData,
                config: props.config,
                rootState: props.rootState,
                modalState: props.modalState,
              })
            }
            color="primary"
          >
            Pay Bill
          </Button>
        )}
        {props.cardData.productType !== ProductType.MEMBERSHIP &&
          props.cardData.viewBillEnabled &&
          !props.cardData.productId.startsWith(AutoPolicyPrefixes.CAR) && (
            <Button
              id="view-bill"
              onClick={() => props.viewBillClick && props.viewBillClick(viewBillClickProps, props.config)}
              color="secondary"
            >
              View bill
            </Button>
          )}
      </CardActions>
    </Card>
  );
};

export const PaymentInTransitCardPropsWithStyles = withStyles(notificationCardStyles, { withTheme: true })(
  PaymentInTransitCard
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInTransitCardPropsWithStyles);
