import * as actions from '../Actions/cardcontainer.actions';
import { AwarenessCard, CardCodeEnum, CardType, Notification } from 'shared';

export interface CardContainerState {
  // contentLoaded: boolean;
  // billingNotificationsLoaded: boolean;
  allCards: AwarenessCard[] | Notification[];
}

export const initialState = {
  // contentLoaded: false,
  // billingNotificationsLoaded: false,
  allCards: [],
};

export const cardContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CARDS:
      return {
        ...state,
        allCards: [...state.allCards, ...action.payload.allCards],
      };
    case actions.UPDATE_CARDS:
      return {
        ...state,
        allCards: [...action.payload.allCards],
      };
    case actions.SET_CANCEL:
      const newState = {...state};
      const cardData: any = newState.allCards.filter((p: any) => (p.productId == action.payload && p.type == "PAYMENT_SCHEDULED"));
      if(cardData.length) {
        cardData[0].cancellationEnabled = false;
        cardData[0].paymentEnabled = true;
        if(cardData[0].billDueDate){
          if(cardData[0].isAutoPay) {
            cardData[0].showRecurringIconFlag = true;
          } else {
            cardData[0].type = "BILLING_DUE";
          }
          cardData[0].date = cardData[0].billDueDate;
          cardData[0].dateInternal = cardData[0].billDueDateInternal;
          cardData[0].amount = cardData[0].billDueAmountInternal;
          if(cardData[0].paymentData.length == 1){
            cardData[0].paymentData[0].minimumDue = Number(cardData[0].billDueAmountInternal);
            cardData[0].paymentData[0].minimumDueDate = cardData[0].billDueDateInternal;
          }
        }
      }
      return {
        ...state
      };
    default:
      return state;
  }
};
