import { CardContainerState } from '../Reducers/cardcontainer.reducer';

export const setCardContainerState = (cardContainerState: CardContainerState) => {
  return (dispatch) => {
    dispatch({ type: SET_CARDS, payload: cardContainerState });
  };
};

export const updateCardContainerState = (cardContainerState: CardContainerState) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CARDS, payload: cardContainerState });
  };
};

export const setCancelPaymentState = (productId: string)  => {
  return (dispatch) => {
    dispatch({ type: SET_CANCEL, payload: productId});
  };
};

export const SET_CARDS = 'SET_CARDS';
export const UPDATE_CARDS = 'UPDATE_CARDS';
export const SET_CANCEL = 'SET_CANCEL';