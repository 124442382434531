import { CiBullhorn } from 'react-icons/ci';

export const PMIDReminderHint = () => {
  return (
    <div
      style={{
        padding: '15px',
        margin: '10px 0 25px 0',
        backgroundColor: '#FFF3D6',
        fontSize: '.9em',
        border: 'none',
        textAlign: 'center',
        borderRadius: '10px',
        display: 'flex',
      }}
      data-testid="pmid-reminder-hint"
    >
      <div style={{ paddingRight: '5px' }}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: 'white',
            height: '35px',
            width: '35px',
          }}
        >
          <CiBullhorn color="#A85D00" style={{ height: '35px', width: '25px' }} />
        </div>
      </div>
      <div style={{ paddingTop: '6px', textAlign: 'justify' }}>
          <p>Complete your enrollment to see all your benefits. It may take an hour for your benefits to appear on your account.</p>
      </div>
    </div>
  );
};
